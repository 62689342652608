<template>
  <Row class="page5">
    <Col style="width:50%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span>{{describeList[chooseIndex]}}</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:50%;height: 94.5%;display: flex;flex-direction: column">
      <div class="center-bottom">
          <span class='title'><span class="title-text">{{picsTitle}}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <Row class="bottom-radars">
            <Col span="6">
              <radar
                  id='bottom_1_1'
                  :data=data5
                  ref="chart1"
              ></radar>
            </Col>
            <Col span="6">
              <radar
                  id='bottom_1_2'
                  :data=data6
                  ref="chart2"
              ></radar>
            </Col>
            <Col span="6">
              <radar
                  id='bottom_1_3'
                  :data=data7
                  ref="chart3"
              ></radar>
            </Col>
            <Col span="6">
              <radar
                  id='bottom_1_4'
                  :data=data8
                  ref="chart4"
              ></radar>
            </Col>
          </Row>
          <Row class="bottom-bars">
            <Col span="12">
              <double-bar
                  id="bottom_2_1"
                  :data="data9"
                  ref="chart5"
              ></double-bar>
            </Col>
            <Col span="12">
              <double-bar
                  id="bottom_2_2"
                  :data="data10"
                  ref="chart6"
              ></double-bar>
            </Col>
<!--            <Col span="6">-->
<!--              <double-bar-->
<!--                  id="bottom_2_3"-->
<!--                  :data="data11"-->
<!--                  ref="chart7"-->
<!--              ></double-bar>-->
<!--            </Col>-->
<!--            <Col span="6">-->
<!--              <double-bar-->
<!--                  id="bottom_2_4"-->
<!--                  :data="data12"-->
<!--                  ref="chart8"-->
<!--              ></double-bar>-->
<!--            </Col>-->
          </Row>
        </div>
      <div class="bottom-list">
        <div class="bottom-left">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">荣誉图片</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
    </div>
  </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
const radar = ()=> import('./components/radar');
const doubleBar =() => import('./components/page3/doubleBar');
// const jobStudy =()=> import('./components/page5/jobStudy')
const workPics = () => import('./components/page2/workPictures');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'page5',
  components: {
    chinaMap,
    // jobStudy,
    workPics,//项目图片
    honorPics, //荣誉图片
    doubleBar,
    radar,
  },
  data() {
    return {
      //tabNav子菜单
      workName:['"红枫妈妈"社区服务','"悦享童年,乐伴成长"金地社区儿童守护项目','洞井街道社工站','侯家塘社工站',],
      chooseIndex:0,
      describeList:[
          ' “红枫妈妈”社区服务项目以社区内的全职陪读妈妈为核心主体，项目充分践行“助人自助”的理念。通过“红枫妈妈课堂”、“红枫妈妈志愿者队伍建设”、“红枫妈妈志愿服务主题活动”等版块活动，充分发挥枫树山社区“红枫妈妈”的力量，强化女性群体在社区的社会支持网络搭建，构建枫树山社区的邻里互助圈。',
          '“悦享童年 乐伴成长”儿童守护项目由砂子塘街道金地社区和长沙市雨花区群英公益发展促进中心联合发起，项目设置常态化开放的儿童之家，在课后和周末为儿童提供服务，链接相关领域的专业志愿者，普及相关知识，补足学校无法覆盖的知识。通过跨社区联动、主题活动的开展，引导儿童积极参与社区事务，丰富儿童在本社区的朋辈支持和前辈的既有经验的学习。',
          '2021年12月，长沙市雨花区群英公益发展促进中心(简称:群英会)通过政府购买服务方式承接洞井街道社会工作服务站2021--2022年的服务工作。洞井街道社工站设在新裕社区万家园小区1栋二楼，为独立社工站办公产地,面积为300多平。站点根据(湖南省乡镇街道社工站视觉化设计指引手册，对社工站进行了可视化的标准建设，包括杜工站特牌，制度上墙，阵地区域划分以及社工站配备2名驻点持证杜工。调井街道社工站围绕打造“社会工作+民政业务”服务品牌,结合洞井街道实际情况,打造“1+3”的服务模式,即发挥1个杜工站枢纽平台作用，以青少年儿童，老年人、困境人群为服务对象打造3个品牌公益项目为基础，同时培育发展党员志愿者服务队，培育舞化社区志庆服务室及社区社会组织,协调区城内社区社会组织。党员吉医服务队券与社区治理，探素洞井街道的社工站建设品牌。',
          '侯家塘街道社会工作服务站，采取政府主导、专业运作的模式，由侯家塘街道委托长沙群英会进行管理运营，是一家以公益为纽带，以“资源共享、资讯互通、合作共赢、服务民生”为开办宗旨，既尽力满足了社会人士对各类社会资讯的需求，较好地引导各个企事业单位和社会组织(团体)积极参与公益履行社会责任，为推动社会治理创新与和谐发展贡献智慧和力量的公益组织。'
        ],
      picsTitle:'', //右下角图片标题
      //地图展示项目数据
      resdata:[
        {
          name: '长沙市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '46',
              serviceCount: '980',
              volunteerCount: '135',
              schoolCount: '3',
              money: '12',
              attent: '0',
            },
            {
              title: '“悦享童年,乐伴成长”金地社区儿童守护项目',
              soprtcount: '104',
              serviceCount: '2868',
              volunteerCount: '50',
              schoolCount: '4',
              money: '2',
              attent: '4',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '67',
              serviceCount: '4707',
              volunteerCount: '32',
              schoolCount: '2',
              money: '12',
              attent: '3',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '11',
              serviceCount: '220',
              volunteerCount: '20',
              schoolCount: '2',
              money: '5',
              attent: '5',
            },
          ]
        },
        {
          name:'株洲市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'湘潭市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'衡阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'常德市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'益阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'娄底市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'郴州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'永州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'怀化市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'湘西土家族苗族自治州',
          value:1,
          choiceIndex:1,
          list:[
      {
        title: '“红枫妈妈”社区服务',
        soprtcount: '0',
        serviceCount: '0',
        volunteerCount: '0',
        schoolCount: '0',
        money: '0',
        attent: '0',
      },
      {
        title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
        soprtcount: '0',
        serviceCount: '0',
        volunteerCount: '0',
        schoolCount: '0',
        money: '0',
        attent: '0',
      },
      {
        title: '洞井街道社工站',
        soprtcount: '0',
        serviceCount: '0',
        volunteerCount: '0',
        schoolCount: '0',
        money: '0',
        attent: '0',
      },
      {
        title: '侯家塘社工站',
        soprtcount: '0',
        serviceCount: '0',
        volunteerCount: '0',
        schoolCount: '0',
        money: '0',
        attent: '0',
      },
    ]
        },
        {
          name:'张家界市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'岳阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'邵阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '“红枫妈妈”社区服务',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“悦享童年 乐伴成长”金地社区儿童守护项目',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '洞井街道社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '侯家塘社工站',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
      ],
      //项目图片数组
      workPicsList:[
        {
          name:'红枫妈妈项目照片',
          picsList:[
            require('@/assets/socialWork/hongfengMom/1.jpg'),
            require('@/assets/socialWork/hongfengMom/2.jpg'),
            require('@/assets/socialWork/hongfengMom/3.jpg'),
            require('@/assets/socialWork/hongfengMom/4.jpg'),
            require('@/assets/socialWork/hongfengMom/5.jpg'),
            require('@/assets/socialWork/hongfengMom/6.jpg'),
            require('@/assets/socialWork/hongfengMom/7.jpg'),
            require('@/assets/socialWork/hongfengMom/8.jpg'),
            require('@/assets/socialWork/hongfengMom/9.jpg'),
            require('@/assets/socialWork/hongfengMom/10.jpg'),
            require('@/assets/socialWork/hongfengMom/11.jpg'),
          ]
        },
        {
          name:'悦享童年 乐伴成长项目照片',
          picsList:[
            require('@/assets/socialWork/happyChildhood/1.jpg'),
            require('@/assets/socialWork/happyChildhood/2.jpg'),
            require('@/assets/socialWork/happyChildhood/3.jpg'),
            require('@/assets/socialWork/happyChildhood/4.jpg'),
            require('@/assets/socialWork/happyChildhood/5.jpg'),
            require('@/assets/socialWork/happyChildhood/6.jpg'),
            require('@/assets/socialWork/happyChildhood/7.jpg'),
            require('@/assets/socialWork/happyChildhood/8.jpg'),
            require('@/assets/socialWork/happyChildhood/9.jpg'),
            require('@/assets/socialWork/happyChildhood/10.jpg'),
          ]
        },
        {
          name:'20221129洞井社工站照片',
          picsList:[
            require('@/assets/socialWork/dongjinCommunity/1.jpg'),
            require('@/assets/socialWork/dongjinCommunity/2.jpg'),
            require('@/assets/socialWork/dongjinCommunity/3.jpg'),
            require('@/assets/socialWork/dongjinCommunity/4.jpg'),
            require('@/assets/socialWork/dongjinCommunity/5.jpg'),
            require('@/assets/socialWork/dongjinCommunity/6.jpg'),
            require('@/assets/socialWork/dongjinCommunity/7.png'),
            require('@/assets/socialWork/dongjinCommunity/8.jpg'),
            require('@/assets/socialWork/dongjinCommunity/9.jpg'),
            require('@/assets/socialWork/dongjinCommunity/10.png'),
          ]
        },
        {
          name:'侯家塘社工站',
          picsList:[]
        },
      ],
      data5: {
        title: '活动场次',
        position: ['5%', '14%'],
        center: ['50%', '60%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
          {text: '娄底市'},
          {text: '郴州市'},
        ],
        data: [
          {
            name: '活动场次数据',
            color: '#0DE4EE',
            value: []
          },
          // {
          //   name: '使用时长',
          //   color: '#0D88F3',
          //   value: [60, 5, 0.30, -100, 1500, 232]
          // }
        ]
      },
      data6: {
        title: '服务人次',
        position: ['5%', '14%'],
        center: ['50%', '60%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
          {text: '娄底市'},
          {text: '郴州市'},
        ],
        data: [
          // {
          //   name: '个人信息涉军',
          //   color: '#6514FF',
          //   value: [100, 8, 0.40, -80, 2000, 345, 12, 654]
          // },
          {
            name: '服务人次数据',
            color: '#B370FD',
            value: []
          }
        ]
      },
      data7: {
        title: '志愿者人数',
        position: ['5%', '14%'],
        center: ['50%', '60%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
          {text: '娄底市'},
          {text: '郴州市'},
        ],
        data: [
          // {
          //   name: '主动',
          //   color: '#0096FE',
          //   value: [100, 8, 0.40, -80, 2000, 345, 123, 21, 34, 54, 431, 876]
          // },
          {
            name: '志愿者人数数据',
            color: '#9EEAFF',
            value: []
          }
        ]
      },
      data8: {
        title: '覆盖学校数量',
        position: ['5%', '14%'],
        center: ['50%', '60%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
        ],
        data: [
          {
            name: '覆盖学校数量数据',
            color: '#FD9800',
            value: [],
          },
          // {
          //   name: 'QQ',
          //   color: '#FDC673',
          //   value: [60, 5, 0.30, -100, 1500, 232]
          // }
        ]
      },
      data9: {
        title: '投入款物（万元）',
        data: [
          {
            name: '投入款物数据',
            color: '#00CCFF',
            value: [],
          },
          // {
          //   name: '使用时长',
          //   color: '#142AFE',
          //   value: ['112', '212', '42', '232', '123', '67']
          // }
        ]
      },
      data10: {
        title: '社会组织参与数',
        data: [
          {
            name: '社会组织参与数数据',
            color: '#B370FD',
            value: [],
          },
          // {
          //   name: '个人信息涉军应用个数',
          //   color: '#6514FF',
          //   value: ['112', '212', '42', '232', '123', '67'],
          // },
        ]
      },
      // data11: {
      //   title: '重点关注异常交友用户',
      //   data: [
      //     {
      //       name: '主动',
      //       color: '#05467D',
      //       value: ['112', '212', '42', '232', '123', '67'],
      //     },
      //     {
      //       name: '被动',
      //       color: '#52B8FF',
      //       value: ['112', '212', '42', '232', '123', '67']
      //     }
      //   ]
      // },
      // data12: {
      //   title: '重点关注用户',
      //   data: [
      //     {
      //       name: '个数',
      //       color: '#FD9800',
      //       value: ['112', '212', '42', '232', '123', '67'],
      //     },
      //     {
      //       name: '使用时长',
      //       color: '#FDC673',
      //       value: ['112', '212', '42', '232', '123', '67']
      //     }
      //   ]
      // },
      resizeFn: null
    }
  },
  methods:{
    //  点击子菜单选择
    chooseNav(item,index){
      this.chooseIndex = index;
      this.picsTitle = item; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false; //加载中
      // 荣誉图片
      // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i=0;i<this.resdata.length;i++){
        this.resdata[i].choiceIndex=index;

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
            + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
            + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
        this.resdata[i].value = totalValue;
      }
      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.$refs.chart1.data.data[0].value = [];
      this.$refs.chart2.data.data[0].value = [];
      this.$refs.chart3.data.data[0].value = [];
      this.$refs.chart4.data.data[0].value = [];
      this.$refs.chart5.data.data[0].value = [];
      this.$refs.chart6.data.data[0].value = [];

      //各城市的数据遍历获取
      this.resdata.forEach(it=>{
        this.$refs.chart1.data.data[0].value.push(it.list[index].soprtcount) //活动场次
        this.$refs.chart2.data.data[0].value.push(it.list[index].serviceCount) //服务人次
        this.$refs.chart3.data.data[0].value.push(it.list[index].volunteerCount) //志愿者人数
        this.$refs.chart4.data.data[0].value.push(it.list[index].schoolCount) //覆盖学校数量
        this.$refs.chart5.data.data[0].value.push(it.list[index].money) //投入款物
        this.$refs.chart6.data.data[0].value.push(it.list[index].attent) //社会组织参与数
      })
      //刷新子组件数据
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
      this.$refs.chart3.setChart();
      this.$refs.chart4.setChart();
      this.$refs.chart5.setChart();
      this.$refs.chart6.setChart();
    },
  },
  mounted() {
    setTimeout(()=>{
      this.chooseNav(this.workName[0],0);
    },100)
    // this.resizeFn = this.$debounce(()=> {
    //   // 通过捕获系统的onresize事件触发我们需要执行的事件
    //   // this.$refs.channelBar1.setChart();
    //   // for (let i = 1; i < 9; i++) {
    //   //   this.$refs['chart' + i].setChart()
    //   //
    //   // }
    //   // this.$refs.chinaMap.setMap();
    //   // this.$refs.hnPronvice.mapEchartsInit();
    //   // this.$refs.hotWords.setChart();
    // }, 500)
    // window.addEventListener('resize', this.resizeFn)
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resizeFn)
  // }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 30%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 40px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left, .center-bottom,.description,.bottom-left,.bottom-right{
    height: 95%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .bottom-left,.bottom-right{
    width: 50%;
    height: 100%!important;
  }
  .bottom-list{
    width: 100%;
    height: 51%!important;
    display: flex;
    box-sizing: border-box;
  }
  .left{
    height: 74%!important;
    background: none!important;
    border: none!important;
  }
  .description{
    background: none!important;
    height: 25.5%!important;
    box-sizing: border-box;
    //padding-bottom: 2%;
    margin-bottom: -2%;
  }
  .describeList{
    text-indent: 1.5%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 1.5% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    //overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #a1b3dc;
  }
  .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }
  .center-bottom {
    height: 48%;
    margin-bottom: 2%;

    .bottom-radars {
      margin-top: 5px;
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

}
</style>
